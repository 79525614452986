import {L10nConfig, L10nLoader} from 'angular-l10n';
import {localization} from './localization-map';

export const l10nConfig: L10nConfig = {
    format: 'language-region',
    providers: [{ name: 'app', asset: localization }],
    cache: true,
    keySeparator: '.',
    defaultLocale: {
        language: 'ru',
    },
    schema: [
        {
            locale: {
                language: 'en',
            },
            dir: 'ltr',
            text: 'English'
        },
        {
            locale: { language: 'ru' },
            dir: 'ltr',
            text: 'Russian'
        }
    ]
};

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
    return () => l10nLoader.init();
}
