import {Routes} from '@angular/router';
import {RootGuard} from './root-module/root-guard.service';


export const layoutRoutes: Routes = [
    {
        path: '',
        loadChildren: () => import('../app/layouts/main/main.module').then(m => m.MainModule),
        canActivate: [RootGuard],
        canActivateChild: [RootGuard]
    }
];
