import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-infosysco-site',
  templateUrl: './root.component.html',
})
export class RootComponent {

    constructor(
        private titleService: Title) {
        this.titleService.setTitle('Информационные системы');
    }

}
