import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of as observableOf} from 'rxjs';


@Injectable()
export class RootGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: Route): Observable<boolean> {
        return observableOf(true);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return observableOf(true);
    }

}
