import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {RootAppModule} from './root-module';
import {RootComponent} from './root-module/root.component';
import {layoutRoutes} from './layout.routes';
import {CommonModule} from '@angular/common';
import {L10nIntlModule, L10nTranslationModule, L10nLoader} from 'angular-l10n';
import {initL10n, l10nConfig} from './l10n-config';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RootAppModule.forRoot(layoutRoutes),
        L10nTranslationModule.forRoot(l10nConfig),
        L10nIntlModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initL10n,
            deps: [L10nLoader],
            multi: true
        }
    ],
    bootstrap: [RootComponent],
})
export class AppModule {
}
