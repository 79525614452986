import {ModuleWithProviders, NgModule} from '@angular/core';
import {Routes, RouterModule, provideRoutes} from '@angular/router';
import {RootGuard} from './root-guard.service';
import {RootComponent} from './root.component';

@NgModule({
    imports: [
        RouterModule.forRoot([], {useHash: false})
    ],
    declarations: [
        RootComponent
    ],
    providers: [
        RootGuard
    ]
})
export class RootAppModule {
    static forRoot(layoutRoutes: Routes): ModuleWithProviders<any> {
        return {
            ngModule: RootAppModule,
            providers: [
                provideRoutes(layoutRoutes),
            ]
        };
    }
}
